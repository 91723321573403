/* eslint-disable no-multi-str */
/* eslint-disable jsx-a11y/img-redundant-alt */
import React, { useState, useContext, useEffect } from 'react';

import Input from './common/Input';
import { Form } from 'react-bootstrap';
import { Editor } from '@tinymce/tinymce-react';
import { XSquare } from 'react-bootstrap-icons';
import Axios from 'axios';
import { CoreContext } from '../context/core-context';
import { AuthContext } from '../context/auth-context';
import { useForm } from "react-hook-form";


const SingleGeneralImage = (props) => {

    const [imageTitle, setImageTitle] = useState(props.image.title);
    const [imageMessage, setImageMessage] = useState(props.image.message);
    const [imageOrder, setImageOrder] = useState(props.image.displayOrder);

    const coreContext = useContext(CoreContext);
    const authContext = useContext(AuthContext);


    const { register, handleSubmit, errors } = useForm({
        mode: 'onSubmit',
        reValidateMode: 'onBlur',
    });
    // const onSubmit = data => console.log(data);

    const onImageDetailsHandler = (data) => {
        coreContext.updateGeneralImage(imageTitle, imageMessage, imageOrder, authContext.itemId, props.image.id);

    }

    const onImageTitleChangedHandler = (e) => {
        setImageTitle(e.target.value);
    }

    const onImageOrderChangedHandler = (e) => {
        setImageOrder(e.target.value);
    }

    const onImageMessageChangedHandler = (content, editor) => {
        setImageMessage(content);
    }


    const deleteImage = () => {
        const id = props.image.id;

        deleteGeneralImage(id);
    }

    const deleteGeneralImage = (id) => {

        Axios.post('/delete-general-image', { id }).then((response) => {
            const images = coreContext.general.general_images;
            const newImages = images.filter(image => image.id !== id);
            const cl = { ...coreContext.general, general_images: newImages };
            coreContext.setGeneral(cl);
        }).catch(err => { });
    }


    return (
        <div className="col-md-3"><img style={{ padding: 5 }} className="img-fluid" src={`http://${props.image.url}`} alt='gallery image' />
            <Form autoComplete='off' onSubmit={handleSubmit(onImageDetailsHandler)} noValidate>
                <Input label='Image Title' value={imageTitle} name='imagtitle' minLength={2} maxLength={155} required={false} register={register} errors={errors} elementType='textarea' placeholder='Enter Image Title' onChange={onImageTitleChangedHandler} />


                <Form.Group>
                    <Editor
                        apiKey={'5k5x5ez6z06mvgu1fkuizuc0wa07bqni14qml1baljvj2ijp'}
                        value={imageMessage ? imageMessage : "Enter Message"}
                        init={{
                            height: 200,
                            menubar: 'insert',
                            file_picker_types: 'file image media',
                            plugins: [
                                'advlist', 'autolink', 'lists', 'link', 'image', 'charmap', 'preview',
                                'anchor', 'searchreplace', 'visualblocks', 'code', 'fullscreen',
                                'insertdatetime', 'media', 'table', 'code', 'help', 'wordcount'
                            ],
                            toolbar:
                                'undo redo | formatselect | bold italic backcolor forecolor  | fontsize fontfamily lineheight | \
             alignleft aligncenter alignright alignjustify | \
             bullist numlist outdent indent image | removeformat | code | help'
                        }}
                        onEditorChange={onImageMessageChangedHandler}
                    />
                </Form.Group>
                <Input label='Image Display Order' value={imageOrder} name='imagemessage' minLength={1} maxLength={4} required={false} register={register} errors={errors} elementType='text' placeholder='Enter Image Reverse Display Order' onChange={onImageOrderChangedHandler} />
                <Input elementType='button' variant='primary' value="Update" /> &nbsp;&nbsp; <button onClick={() => deleteImage()} type="button" className="btn btn-danger btn-sm" title="Delete Image"> <XSquare size="12" /> </button> &nbsp;&nbsp; {coreContext.renderLoader()}
                <Input variant='danger' label={coreContext.message} elementType='label' />
            </Form>

        </div>
    )
}

export { SingleGeneralImage }