/* eslint-disable react/no-danger-with-children */
/* eslint-disable jsx-a11y/img-redundant-alt */
import React, { useState, useContext, useEffect } from 'react';
import { useForm } from 'react-hook-form';
import { CoreContext } from '../context/core-context';
import { AuthContext } from '../context/auth-context';
import { XSquare } from 'react-bootstrap-icons';
import { Badge, Form } from 'react-bootstrap';
import Input from './common/Input';
import ImageUploader from './common/UploadImage';


const Client = (props) => {
    const coreContext = useContext(CoreContext);
    const authContext = useContext(AuthContext);

    const [name, setName] = useState('');
    const [contactName, setContactName] = useState('');
    const [tag, setTag] = useState('');
    const [loc, setLOC] = useState('');
    const [email, setEmail] = useState('');
    const [contact1, setContact1] = useState('');
    const [contact2, setContact2] = useState();
    const [contact, setContact] = useState();
    const [address, setAddress] = useState();
    const [description, setDescription] = useState();
    const [videoLink, setVideoLink] = useState('');
    const [pincode, setPincode] = useState();
    const [password, setPassword] = useState();
    const [localArea, setLocalArea] = useState('');
    const [maxImagesAllowed, setMaxImagesAllowed] = useState(6);
    const [stateId, setStateId] = useState('');
    const [districtId, setDistrictId] = useState('');
    const [category, setCategory] = useState('');
    const [countryId, setCountryId] = useState('');
    const [erpBranchid, setErpBranchid] = useState('');
    const [isCollege, setIsCollege] = useState('no');
    const [hasImages, setHasImages] = useState(false);
    const [hideMenu, setHideMenu] = useState('no');
    const [hideFooter, setHideFooter] = useState('no');
    const [images, setImages] = useState([]);
    const [videos, setVideos] = useState([]);
    const [uploadButtonDisabled, setUploadButtonDisabled] = useState(true);
    const [videoUploadButtonDisabled, setVideoUploadButtonDisabled] = useState(true);
    const [selectedImages, setSelectedImages] = useState([]);
    const [hasVideos, setHasVideos] = useState(false);
    const [hasRatings, setHasRatings] = useState(false);
    const [statusOptions] = useState([{ name: 'Active', value: 'active' }, { name: 'Inactive', value: 'inactive' }]);
    const [isCollegeOptions] = useState([{ name: 'Yes', value: 'yes' }, { name: 'No', value: 'no' }]);
    const [status, setStatus] = useState('');
    const [siteUrl, setSiteURL] = useState('');

    //useEffect(setClient(coreContext.client), [client]);

    const fetchClient = () => {

        //this.props.match.params.id //accessing param
        // console.log('paramas', props.match);
        coreContext.fetchClient(props.location, authContext.itemId);
    }

    useEffect(fetchClient, []);


    const loadLoactionOptions = () => {
        coreContext.fetchCountries();
        if (coreContext.client.countryId)
            coreContext.fetchStates(coreContext.client.countryId);
        if (coreContext.client.stateId)
            coreContext.fetchDistricts(coreContext.client.countryId, coreContext.client.stateId);
        if (coreContext.client.id)
            coreContext.fetchLocalAreas(coreContext.client.id);
    }

    const deleteImage = (id) => {
        const newImages = images.filter(image => image.id !== id);
        setImages(newImages);
        coreContext.deleteImage(id);
    }

    const deleteVideo = (id) => {
        const newVideos = videos.filter(video => video.id !== id);
        setVideos(newVideos);
        coreContext.deleteVideo(id);
    }

    const populateClient = () => {
        console.log(coreContext.client);
        setName(coreContext.client.item_name);
        setContactName(coreContext.client.item_contact_person);
        setTag(coreContext.client.item_tag);
        setLOC(coreContext.client.item_loc);
        setEmail(coreContext.client.item_email);
        setErpBranchid(coreContext.client.erp_branchid);
        setIsCollege(coreContext.client.is_college);
        setContact1(coreContext.client.item_mobile_1);
        setContact2(coreContext.client.item_mobile_2);
        setContact(coreContext.client.item_contact);
        setAddress(coreContext.client.item_address);
        setDescription(coreContext.client.item_description);
        setPincode(coreContext.client.item_pincode);
        setLocalArea(coreContext.client.areaId);
        setStateId(coreContext.client.stateId);
        setDistrictId(coreContext.client.districtId);
        setCategory(coreContext.client.categoryId);
        setCountryId(coreContext.client.countryId);
        setImages(coreContext.client.images);
        setVideos(coreContext.client.video_links);
        setStatus(coreContext.client.item_status);
        setSiteURL(coreContext.client.site_url);
        setHideMenu(coreContext.client.hide_menu);
        setHideFooter(coreContext.client.hide_footer);
        if (coreContext.client.has_images === 'no')
            setHasImages(false);
        else setHasImages(true);
        if (coreContext.client.has_videos === 'no')
            setHasVideos(false);
        else
            setHasVideos(true);
        if (coreContext.client.has_ratings === 'no')
            setHasRatings(false);
        else
            setHasRatings(true);

    }

    useEffect(loadLoactionOptions, [coreContext.client]);
    useEffect(populateClient, [coreContext.client]);

    const renderImages = () => {
        if (images)
            return images.map(image => <div className="col-md-6"><img style={{ padding: 5 }} className="img-fluid" src={`http://${image.url}`} alt='gallery image' /> <button onClick={() => deleteImage(image.id)} style={{ position: 'absolute', top: 0, right: 15 }} type="button" className="btn btn-danger btn-sm" title="Delete Image"> <XSquare size="12" /> </button></div>)
    }

    const renderVideos = () => {
        //console.log(coreContext.client);
        if (videos)
            return videos.map(video => <div className="col-md-12"> <div dangerouslySetInnerHTML={{ __html: video.url }} /> <button onClick={() => deleteVideo(video.id)} style={{ position: 'absolute', top: 0, right: 15 }} type="button" className="btn btn-danger btn-sm" title="Delete Video"> <XSquare size="12" /> </button></div>)
    }


    const { register, handleSubmit, errors } = useForm({
        mode: 'onSubmit',
        reValidateMode: 'onBlur'
    });


    const clientEditHandler = (data) => {
        coreContext.editClient(coreContext.client.id, authContext.itemId, name, email, address, contact1, contact2, contactName, tag, loc, contact, description, pincode, localArea, stateId, districtId, category, countryId, status, siteUrl, hideMenu, hideFooter, erpBranchid, isCollege);

    }

    const onImagesSelected = (pictures) => {
        setSelectedImages(pictures);
        //  console.log(pictures);
        if (pictures.length > 0) setUploadButtonDisabled(false); else setUploadButtonDisabled(true);
    }

    const renderImageUploader = () => {
        if (hasImages) return <ImageUploader onImagesSelected={onImagesSelected} maxWidthOrHeight='400' pictures={selectedImages} />
        else return '';
    }

    const renderVideoUploader = () => {
        if (hasVideos) return <Input label='Video Youtube Embed Link' value={videoLink} name='videolink' minLength={5} maxLength={255} required={false} register={register} errors={errors} elementType='textarea' placeholder='Enter Youtube Video Embed Link' onChange={onVideoLinkChangedHandler} />
        else return '';
    }

    const onImagesUploadHandler = async () => {
        const count = selectedImages.length;
        let counter = 0;
        const preImagesCount = images.length;

        const imagesNowAllowed = maxImagesAllowed - preImagesCount;

        if (count > imagesNowAllowed) { alert('You can now upload a maximum of ' + imagesNowAllowed + ' images.'); return }

        for await (const image of selectedImages) {
            ++counter;
            const formData = new FormData();
            formData.append('itemId', coreContext.client.id);
            formData.append('image', image, image.name);
            formData.append('userId', authContext.userId);
            coreContext.uploadImage(formData, coreContext.client.id);

            if (counter === count) {
                setTimeout(() => {
                    setSelectedImages([]);
                    coreContext.fetchImages(coreContext.client.id);
                }, 2000);
            };
        }



    }

    const onVideosUploadHandler = async () => {
        await coreContext.uploadVideoLink(videoLink, coreContext.client.id, authContext.userId);
        setTimeout(() => {
            coreContext.fetchVideos(coreContext.client.id);
        }, 2000);

    }

    const onNameChangedHandler = (e) => {
        setName(e.target.value);
    }

    const onVideoLinkChangedHandler = (e) => {
        setVideoLink(e.target.value);
        const url = e.target.value;
        if (url.length > 5) setVideoUploadButtonDisabled(false); else setVideoUploadButtonDisabled(true);
    }

    const onImageChangedHandler = (e) => {
        if (hasImages) {
            setHasImages(false);
            coreContext.updateHasImage(authContext.itemId, coreContext.client.id, 'no');
        }
        else {
            setHasImages(true);
            coreContext.updateHasImage(authContext.itemId, coreContext.client.id, 'yes');
        }

        setSelectedImages([]);
        setUploadButtonDisabled(true);
    }

    const onVideoChangedHandler = (e) => {
        if (hasVideos) {
            setHasVideos(false);
            coreContext.updateHasVideo(authContext.itemId, coreContext.client.id, 'no');
        }
        else {
            setHasVideos(true);
            coreContext.updateHasVideo(authContext.itemId, coreContext.client.id, 'yes');
        }
        setVideoUploadButtonDisabled(true);
    }

    const onConNameChangedHandler = (e) => {
        setContactName(e.target.value);
    }

    const onPinChangedHandler = (e) => {
        setPincode(e.target.value);
    }

    const onAddressChangedHandler = (e) => {
        setAddress(e.target.value);
    }

    const onDescriptionChangedHandler = (e) => {
        setDescription(e.target.value);
    }

    const onLOCChangedHandler = (e) => {
        setLOC(e.target.value);
    }

    const onTagChangedHandler = (e) => {
        setTag(e.target.value);
    }

    const onEmailChangedHandler = (e) => {
        setEmail(e.target.value);
    }

    const onContact1Handler = (e) => {
        setContact1(e.target.value);
    }

    const onPasswordChangedHandler = (e) => {
        setPassword(e.target.value);
    }

    const onContactHandler = (e) => {
        setContact(e.target.value);
    }

    const onContact2Handler = (e) => {
        setContact2(e.target.value);
    }

    const onLocalAreaChangedHandler = (e) => {
        setName(e.target.value);
    }

    const onCountryChangedHandler = (e) => {
        setCountryId(e.target.value);
        coreContext.fetchStates(e.target.value);
    }

    const onCategoryChangedHandler = (e) => {
        setCategory(e.target.value);
    }

    const onHideMenuChangedHandler = (e) => {

        if (hideMenu === 'no') setHideMenu('yes');
        else setHideMenu('no');
    }
    const onHideFooterChangedHandler = (e) => {
        if (hideFooter === 'no') setHideFooter('yes');
        else setHideFooter('no');
    }

    const onStateChangedHandler = (e) => {
        setStateId(e.target.value);
        coreContext.fetchDistricts(countryId, e.target.value);
    }

    const onDistrictChangedHandler = (e) => {
        setDistrictId(e.target.value);
    }

    const onURLChangedHandler = (e) => {
        setSiteURL(e.target.value);
    }

    const onBranchidChangedHandler = (e) => {
        setErpBranchid(e.target.value);
    }

    const onStatusChangedHandler = (e) => {
        setStatus(e.target.value);
    }

    const onIsCollegeHandler = (e) => {
        setIsCollege(e.target.value);
    }

    return (<div className='container'>
        <div className="row" >
            <div className="col-md-6">
                <div className="card">
                    <div className="card-title mx-auto"><h3><Badge variant="success">Modify Client</Badge></h3></div>
                    <div className="card-body">
                        <Form autoComplete='off' onSubmit={handleSubmit(clientEditHandler)} noValidate>
                            <Input label='Name' value={name} name='name' minLength={5} maxLength={55} required={true} register={register} errors={errors} elementType='text' placeholder='Enter Name' onChange={onNameChangedHandler} />

                            <Input label='Website URL' name='site_url' value={siteUrl} minLength={2} maxLength={255} required={false} register={register} errors={errors} elementType='textarea' placeholder='Website URL' onChange={onURLChangedHandler} />

                            <Input label='ERP Branch ID' name='erp_branchid' value={erpBranchid} minLength={5} maxLength={255} required={false} register={register} errors={errors} elementType='textarea' placeholder='ERP Branch ID' onChange={onBranchidChangedHandler} />

                            <Input label='Is a College' value={isCollege} name='is_college' required={true} register={register} errors={errors} elementType='select' options={isCollegeOptions} onChange={onIsCollegeHandler} />

                            <Input label='Title' value={tag} name='tag' minLength={5} maxLength={555} required={false} register={register} errors={errors} elementType='textarea' placeholder='Enter Site Title . eg: "Siddhanta Technnology Services || The best School ERP provider || best Mobile App provider" ...Google use this title for SEO' onChange={onTagChangedHandler} />

                            <Input label='Meta Description' value={description} name='description' minLength={5} maxLength={555} required={false} register={register} errors={errors} elementType='textarea' placeholder='Enter Meta Description ... eg. "We are the best school in Muzaffarpur."  ...Google use these descriptions for SEO.' onChange={onDescriptionChangedHandler} />

                            <Input label='LOC' name='log' value={loc} minLength={2} maxLength={555} required={false} register={register} errors={errors} elementType='textarea' placeholder='Google Map Embed Code  from map.google.com' onChange={onLOCChangedHandler} />

                            <Input label='Contact Person Name' name='contactName' value={contactName} minLength={2} maxLength={55} required={false} register={register} errors={errors} elementType='text' placeholder='Contact Person Name' onChange={onConNameChangedHandler} />

                            <Input label='Contact' value={contact} name='contact' minLength={10} maxLength={20} required={true} register={register} errors={errors} elementType='text' placeholder='Enter Contact No.' onChange={onContactHandler} />

                            <Input label='Mobile-I' name='contact1' value={contact1} minLength={10} maxLength={10} required={true} register={register} errors={errors} elementType='text' placeholder='Enter Contact No 1.' onChange={onContact1Handler} />

                            <Input label='Mobile-II' value={contact2} name='contact2' minLength={10} maxLength={10} required={false} register={register} errors={errors} elementType='text' placeholder='Enter Contact No 2.' onChange={onContact2Handler} />

                            <Input label='Email' name='email' value={email} required={true} register={register} errors={errors} elementType='text' readOnly={true} minLength={5} maxLength={55} pattern={/[a-z0-9!#$%&'*+/=?^_`{|}~-]+(?:\.[a-z0-9!#$%&'*+/=?^_`{|}~-]+)*@(?:[a-z0-9](?:[a-z0-9-]*[a-z0-9])?\.)+[a-z0-9](?:[a-z0-9-]*[a-z0-9])?/} placeholder='Enter Email' onChange={onEmailChangedHandler} />

                            {/* <Input label='Reset Password' required={false} register={register} errors={errors} name='password' minLength={5} maxLength={55} elementType='password' placeholder='Reset Password' onChange={onPasswordChangedHandler} /> */}

                            <Input label='Address' value={address} name='address' minLength={5} maxLength={155} required={false} register={register} errors={errors} elementType='textarea' placeholder='Enter Address' onChange={onAddressChangedHandler} />

                            <Input label='Pincode' value={pincode} name='pincode' minLength={6} maxLength={6} required={false} register={register} errors={errors} elementType='text' placeholder='Enter Pincode' onChange={onPinChangedHandler} />

                            <Input label='Status' value={status} name='status' required={true} register={register} errors={errors} elementType='select' options={statusOptions} onChange={onStatusChangedHandler} />

                            <Input label='Category' name='category' required={true} register={register} errors={errors} elementType='select' value={category} options={coreContext.categoryOptions} onChange={onCategoryChangedHandler} />

                            <Input label='Country' name='country' required={true} register={register} errors={errors} elementType='select' value={countryId} options={coreContext.countryOptions} onChange={onCountryChangedHandler} />

                            <Input label='State' name='state' required={true} register={register} errors={errors} elementType='select' value={stateId} options={coreContext.stateOptions} onChange={onStateChangedHandler} />

                            <Input label='District' name='district' required={true} register={register} errors={errors} elementType='select' value={districtId} options={coreContext.districtOptions} onChange={onDistrictChangedHandler} />

                            <Input label='Local Area' name='localarea' required={false} register={register} errors={errors} elementType='select' value={localArea} options={coreContext.localAreaOptions} onChange={onLocalAreaChangedHandler} />

                            <div className='row'>
                                <div className="col-md-6"> <Input elementType='checkbox' value="Hide Menu" checked={hideMenu === 'yes'} onChange={onHideMenuChangedHandler} /></div>
                                <div className="col-md-6"><Input elementType='checkbox' value="Hide Footer" checked={hideFooter === 'yes'} onChange={onHideFooterChangedHandler} /></div>
                            </div>

                            <Input elementType='button' variant='primary' value="Update" /> &nbsp;&nbsp; {coreContext.renderLoader()}
                            <Input variant='danger' label={coreContext.message} elementType='label' />
                        </Form>
                    </div>
                </div>
            </div>
            <div className="col-md-6" style={{ maxHeight: '500px', overflowY: 'auto', position: 'sticky', top: '50px' }}>
                <div className="card">
                    <div className="card-header">
                        <Input elementType='checkbox' value="Images" checked={hasImages} onChange={onImageChangedHandler} />
                    </div>
                    <div className="card-body">
                        {renderImageUploader()}
                        <Input value='Upload Images' onClick={onImagesUploadHandler} disabled={uploadButtonDisabled} elementType='button' variant='primary' />&nbsp;&nbsp; {coreContext.renderLoader()}
                        <Input variant='danger' label={coreContext.message} elementType='label' />
                    </div>

                </div>
                <div className="card">
                    <div className="card-header">All Images</div>
                    <div className="card-body">
                        <div className="row">
                            {renderImages()}
                        </div>
                    </div>
                </div>

                <div className="card">
                    <div className="card-header">
                        <Input elementType='checkbox' value="You Tube Videos" checked={hasVideos} onChange={onVideoChangedHandler} />
                    </div>
                    <div className="card-body">
                        {renderVideoUploader()}
                        <Input value='Upload Video Links' onClick={onVideosUploadHandler} disabled={videoUploadButtonDisabled} elementType='button' variant='primary' />&nbsp;&nbsp; {coreContext.renderLoader()}
                        <Input variant='danger' label={coreContext.message} elementType='label' />
                    </div>
                    <div className="card">
                        <div className="card-header">All Videos</div>
                        <div className="card-body">
                            <div className="row">
                                {renderVideos()}
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div> </div>);
}


export { Client };