import React, { useContext, useEffect } from 'react';
import { AuthContext } from '../context/auth-context';
import { Redirect } from 'react-router-dom';
import { Login } from './Login';
const LogOut = () => {

    const authContext = useContext(AuthContext);

    useEffect(authContext.logOut, []);
    return <Redirect to={Login} />;

}


export { LogOut };