import React, { useContext, useState } from "react";
import { useForm } from "react-hook-form";
import { Form, Badge } from 'react-bootstrap';
import { AuthContext } from '../context/auth-context';
import Input from './common/Input';



const Login = (props) => {
    const authContext = useContext(AuthContext);
    const login = () => {
        authContext.login(email, password, props.match.url);
    }

    const [email, setEmail] = useState('');
    const [password, setPassword] = useState('');

    const onEmailChangedHandler = (e) => {
        setEmail(e.target.value);
    }

    const onPasswordChangedHandler = (e) => {
        setPassword(e.target.value);
    }
    const { register, handleSubmit, errors } = useForm({
        mode: 'onSubmit',
        reValidateMode: 'onBlur',
    });


    return (
        <div className='container'>
            <div className="row">
                <div className="col-md-2"></div>
                <div className="col-md-8">
                    <div className="card">
                        <div className="card-title mx-auto"><h3><Badge variant="success">Sign In</Badge></h3></div>
                        <div className="card-body">
                            <Form autoComplete='off' onSubmit={handleSubmit(login)} noValidate>
                                <Input label='Email' elementType='email' minLength={5} maxLength={55} placeholder='Enter Email' onChange={onEmailChangedHandler} name='email' required={true} register={register} errors={errors} pattern={/[a-z0-9!#$%&'*+/=?^_`{|}~-]+(?:\.[a-z0-9!#$%&'*+/=?^_`{|}~-]+)*@(?:[a-z0-9](?:[a-z0-9-]*[a-z0-9])?\.)+[a-z0-9](?:[a-z0-9-]*[a-z0-9])?/} />
                                <Input label='Password' elementType='password' placeholder='Enter Password' onChange={onPasswordChangedHandler} required={true} minLength={5} maxLength={55} register={register} errors={errors} name='password' />
                                <Input elementType='button' variant='primary' /> &nbsp;&nbsp; {authContext.renderLoader()}
                                <Input variant='danger' label={authContext.message} elementType='label' />
                            </Form>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );

}






export { Login };