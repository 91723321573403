import React, { useState, useContext, useEffect } from "react";
import { Form, Badge } from 'react-bootstrap';
import { useForm } from "react-hook-form";
import Input from './common/Input';
import { AuthContext } from '../context/auth-context';
import { CoreContext } from '../context/core-context';


const AddUser = (props) => {

    const authContext = useContext(AuthContext);
    const coreContext = useContext(CoreContext);

    const [email, setEmail] = useState('');
    const [name, setName] = useState('');
    const [contact1, setContact1] = useState('');
    const [contact2, setContact2] = useState('');
    const [password, setPassword] = useState('');
    const [role, setRole] = useState('');

    const { register, handleSubmit, errors } = useForm({
        mode: 'onSubmit',
        reValidateMode: 'onBlur',
    });
    // const onSubmit = data => console.log(data);

    const roleOptions = () => {
        coreContext.fetchRoleOptions();
    }

    useEffect(roleOptions, []);

    const signUpHandler = (data) => {
        authContext.addUser(name, email, password, role, contact1, contact2);

    }




    const onEmailChangedHandler = (e) => {
        setEmail(e.target.value);
    }

    const onContact1Handler = (e) => {
        setContact1(e.target.value);
    }

    const onContact2Handler = (e) => {
        setContact2(e.target.value);
    }

    const onRoleChangedHandler = (e) => {
        setRole(e.target.value);
    }

    const onNameChangedHandler = (e) => {
        setName(e.target.value);
    }

    const onPasswordChangedHandler = (e) => {
        setPassword(e.target.value);
    }


    return (
        <div className='container'>
            <div className="row">
                <div className="col-md-2"></div>
                <div className="col-md-8">
                    <div className="card">
                        <div className="card-title mx-auto"><h3><Badge variant="success">Sign Up A New User</Badge></h3></div>
                        <div className="card-body">
                            <Form autoComplete='off' onSubmit={handleSubmit(signUpHandler)} noValidate>
                                <Input label='Name' name='name' minLength={5} maxLength={55} required={true} register={register} errors={errors} elementType='text' placeholder='Enter Name' onChange={onNameChangedHandler} />


                                <Input label='Email' name='email' required={true} register={register} errors={errors} elementType='email' minLength={5} maxLength={55} pattern={/[a-z0-9!#$%&'*+/=?^_`{|}~-]+(?:\.[a-z0-9!#$%&'*+/=?^_`{|}~-]+)*@(?:[a-z0-9](?:[a-z0-9-]*[a-z0-9])?\.)+[a-z0-9](?:[a-z0-9-]*[a-z0-9])?/} placeholder='Enter Email' onChange={onEmailChangedHandler} />


                                <Input label='Password' required={true} register={register} errors={errors} name='password' minLength={5} maxLength={55} elementType='password' placeholder='Enter Password' onChange={onPasswordChangedHandler} />

                                <Input label='Contact-I' name='contact1' minLength={10} maxLength={10} required={true} register={register} errors={errors} elementType='text' placeholder='Enter Contact No 1.' onChange={onContact1Handler} />

                                <Input label='Contact-II' name='contact2' minLength={10} maxLength={10} required={false} register={register} errors={errors} elementType='text' placeholder='Enter Contact No 2.' onChange={onContact2Handler} />

                                <Input label='Role' name='role' required={true} register={register} errors={errors} elementType='select' options={coreContext.roleOptions} onChange={onRoleChangedHandler} />


                                <Input elementType='button' variant='primary' /> &nbsp;&nbsp; {authContext.ren}
                                <Input variant='danger' label={authContext.message} elementType='label' />
                            </Form>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );

}



export { AddUser };