/* eslint-disable jsx-a11y/img-redundant-alt */
import React, { useState, useContext, useEffect } from 'react';

import Input from './common/Input';
import { Form } from 'react-bootstrap';
import Axios from 'axios';
import { XSquare } from 'react-bootstrap-icons';
import { CoreContext } from '../context/core-context';
import { AuthContext } from '../context/auth-context';
import { useForm } from "react-hook-form";


const SingleSlider = (props) => {

    const [imageTitle, setImageTitle] = useState(props.image.imagetitle);
    const [imageMessage, setImageMessage] = useState(props.image.imagemessage);
    const [button1Title, setButton1Title] = useState(props.image.button1title);
    const [button2Title, setButton2Title] = useState(props.image.button2title);
    const [button1Link, setButton1Link] = useState(props.image.button1link);
    const [button2Link, setButton2Link] = useState(props.image.button2link);

    const coreContext = useContext(CoreContext);
    const authContext = useContext(AuthContext);

    const fetchClient = () => {
        coreContext.fetchSelf(authContext.itemId);
    }

    useEffect(fetchClient, []);


    const { register, handleSubmit, errors } = useForm({
        mode: 'onSubmit',
        reValidateMode: 'onBlur',
    });
    // const onSubmit = data => console.log(data);

    const onImageDetailsHandler = (data) => {
        coreContext.updateSliderImage(imageTitle, imageMessage, button1Title, button2Title, button1Link, button2Link, authContext.itemId, props.image.id);

    }

    const onImageTitleChangedHandler = (e) => {
        setImageTitle(e.target.value);
    }

    const onImageMessageChangedHandler = (e) => {
        setImageMessage(e.target.value);
    }

    const onButton1TitleChangedHandler = (e) => {
        setButton1Title(e.target.value);
    }
    const onButton1LinkChangedHandler = (e) => {
        setButton1Link(e.target.value);
    }
    const onButton2TitleChangedHandler = (e) => {
        setButton2Title(e.target.value);
    }
    const onButton2LinkChangedHandler = (e) => {
        setButton2Link(e.target.value);
    }

    const deleteImage = () => {
        const id = props.image.id;
        deleteSliderImage(id);
    }

    const deleteSliderImage = (id) => {

        Axios.post('/delete-slider-image', { id }).then((response) => {
            const images = coreContext.homeSliderImages;
        const newImages = images.filter(image => image.id !== id);
        coreContext.setHomeSliderImages(newImages);
        }).catch(err => { });
    }


    return (
        <div className="col-md-3"><img style={{ padding: 5 }} className="img-fluid" src={`http://${props.image.url}`} alt='gallery image' /> 
            <Form autoComplete='off' onSubmit={handleSubmit(onImageDetailsHandler)} noValidate>
                <Input label='Image Title' value={imageTitle} name='imagtitle' minLength={2} maxLength={155} required={false} register={register} errors={errors} elementType='textarea' placeholder='Enter Image Title' onChange={onImageTitleChangedHandler} />

                <Input label='Image Message' value={imageMessage} name='imagemessage' minLength={2} maxLength={155} required={false} register={register} errors={errors} elementType='textarea' placeholder='Enter Image Message' onChange={onImageMessageChangedHandler} />

                <Input label='Button-1 Title' value={button1Title} name='button1title' minLength={2} maxLength={25} required={false} register={register} errors={errors} elementType='textarea' placeholder='Enter Button-1 Title' onChange={onButton1TitleChangedHandler} />

                <Input label='Button-1 Link' value={button1Link} name='button1link' minLength={2} maxLength={155} required={false} register={register} errors={errors} elementType='textarea' placeholder='Enter Button-1 Link' onChange={onButton1LinkChangedHandler} />

                <Input label='Button-2 Title' value={button2Title} name='button2title' minLength={2} maxLength={25} required={false} register={register} errors={errors} elementType='textarea' placeholder='Enter Button-2 Title' onChange={onButton2TitleChangedHandler} />

                <Input label='Button-2 Link' value={button2Link} name='button2link' minLength={2} maxLength={155} required={false} register={register} errors={errors} elementType='textarea' placeholder='Enter Button-2 Link' onChange={onButton2LinkChangedHandler} />

                <Input elementType='button' variant='primary' value="Update" /> &nbsp;&nbsp;  <button onClick={() => deleteImage()} type="button" className="btn btn-danger btn-sm" title="Delete Image"> <XSquare size="12" /> </button> &nbsp;&nbsp; {coreContext.renderLoader()}
                <Input variant='danger' label={coreContext.message} elementType='label' />
            </Form>
        </div>
    )
}

export { SingleSlider }