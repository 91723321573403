import React, { useContext, useEffect } from 'react';
import { Badge } from 'react-bootstrap';
import { Eye } from 'react-bootstrap-icons';
import { CoreContext } from '../context/core-context';
import { AuthContext } from '../context/auth-context';
import { Link } from 'react-router-dom';

const Clients = (props) => {

    const coreContext = useContext(CoreContext);
    const authContext = useContext(AuthContext);

    const fetchClients = () => {
        coreContext.fetchClients(authContext.itemId);
    }

    useEffect(fetchClients, []);


    const renderClients = () => {
        if (coreContext.clients) {
            return coreContext.clients.map((client, index) => (
                <tr key={++index}>
                    <td>{index}</td>
                    <td>{client.item_name}</td>
                    <td>{client.item_email}</td>
                    <td>{client.item_address}</td>
                    <td>{client.item_status}</td>
                    <td><Link className="btn btn-primary" to={`/client/${client.id}`} title="Manage Client"><Eye size="12" /></Link></td>
                </tr>
            ));
        }
        else {
            return (<tr>
                <td></td>
                <td></td>
                <td></td>
                <td></td>
                <td></td>
                <td></td>
            </tr>)
        }
    }

    return (

        <div className="card">
            <div className="card-title mx-auto">
                <h3>
                    <Badge variant="success">Client List</Badge>
                </h3>
            </div>
            <div className="card-body table-responsive">
                <table className='table table-sm table-bordered'>
                    <thead>
                        <tr>
                            <th></th>
                            <th>Name</th>
                            <th>Email</th>
                            <th>Address</th>
                            <th>Status</th>
                            <th></th>
                        </tr>
                    </thead>
                    <tbody>
                        {renderClients()}
                    </tbody>
                </table>
            </div>
        </div>

    );

}


export { Clients };