/* eslint-disable jsx-a11y/img-redundant-alt */
import React, { useState, useContext, useEffect } from 'react';
import ImageUploader from './common/UploadImage';
import Input from './common/Input';
import { useHistory } from 'react-router-dom';
import { SingleEducationalSolutionImage } from './SingleEducationalSolutionImage';
import { Badge, Form } from 'react-bootstrap';
import { CoreContext } from '../context/core-context';
import { AuthContext } from '../context/auth-context';
import { useForm } from "react-hook-form";


const EducationSolution = () => {

    const coreContext = useContext(CoreContext);
    const authContext = useContext(AuthContext);
    const [selectedImages, setSelectedImages] = useState([]);
    const [uploadButtonDisabled, setUploadButtonDisabled] = useState(true);
    const [title, setTitle] = useState('');
    const [message, setMessage] = useState('');
    const [columns, setColumns] = useState(3);

    const history = useHistory();

    const allColumns = [{ value: '3', name: 'Three' }, { value: '4', name: 'Four' }];

    const fetchClient = () => {
        coreContext.fetchSelfEducationalSolutions(authContext.itemId);
    }

    const { register, handleSubmit, errors } = useForm({
        mode: 'onSubmit',
        reValidateMode: 'onBlur',
    });

    useEffect(fetchClient, []);

    const populateClient = () => {
        setTitle(coreContext.client.item_educational_solutions_title);
        setMessage(coreContext.client.item_educational_solutions_message);
        setColumns(coreContext.client.item_educational_solutions_column);
    }

    useEffect(populateClient, [coreContext.client]);

    const renderImages = () => {
        const images = coreContext.educationalSolutionsImages;
        if (images)
            return images.map(image => <SingleEducationalSolutionImage key={image.id} image={image} />)
    }

    const onImagesUploadHandler = async () => {
        const count = selectedImages.length;
        let counter = 0;

        for await (const image of selectedImages) {
            ++counter;
            const formData = new FormData();
            formData.append('itemId', coreContext.client.id);
            formData.append('image', image, image.name);
            formData.append('userId', authContext.userId);
            coreContext.uploadEducationalSolutionsImage(formData, coreContext.client.id);

            if (counter === count) {
                setTimeout(() => {
                    setSelectedImages([]);
                    coreContext.fetchEducationSolutionsImages(coreContext.client.id);
                }, 2000);

                setTimeout(() => {
                    history.push('/');
                }, 5000);
            };
        }



    }

    const onTitleChangedHandler = (e) => {
        setTitle(e.target.value);
    }

    const onColumnsChangedHandler = (e) => {
        setColumns(e.target.value);
    }
    const onMessageChangedHandler = (e) => {
        setMessage(e.target.value);
    }

    const onEducationSolutionSectionHandler = (data) => {
        coreContext.updateEducationalSolutionsSection(title, message, columns, authContext.itemId);

    }

    const onImagesSelected = (pictures) => {
        setSelectedImages(pictures);
        //  console.log(pictures);
        if (pictures.length > 0) setUploadButtonDisabled(false); else setUploadButtonDisabled(true);
    }

    return (<div className='container-fluid'>
        <div className="card">
            <div className="card-title mx-auto"><h3><Badge variant="success">Educational Solutions Section</Badge></h3></div>
            <div className="card-body">
                <Form autoComplete='off' onSubmit={handleSubmit(onEducationSolutionSectionHandler)} noValidate>
                    <Input label='Title' value={title} name='title' minLength={2} maxLength={155} required={false} register={register} errors={errors} elementType='textarea' placeholder='Enter Title' onChange={onTitleChangedHandler} />

                    <Input label='Image Message' value={message} name='message' minLength={2} maxLength={2000} required={false} register={register} errors={errors} elementType='textarea' placeholder='Enter Message' onChange={onMessageChangedHandler} />

                    <Input label='Image Columns' name='columns' required={true} register={register} errors={errors} elementType='select' value={columns} options={allColumns} onChange={onColumnsChangedHandler} />

                    <Input elementType='button' variant='primary' value="Update" /> &nbsp;&nbsp; {coreContext.renderLoader()}
                    <Input variant='danger' label={coreContext.message} elementType='label' />
                </Form>
            </div>
        </div>
        <div className="card">
            <div className="card-title mx-auto"><h3><Badge variant="success">Upload Educational Solution Images</Badge></h3></div>
            <div className="card-title mx-auto">Best Image Size 371* 250</div>
            <div className="card-body">
                <ImageUploader onImagesSelected={onImagesSelected} maxWidthOrHeight='375' pictures={selectedImages} />
                <Input value='Upload Images' onClick={onImagesUploadHandler} disabled={uploadButtonDisabled} elementType='button' variant='primary' />&nbsp;&nbsp; {coreContext.renderLoader()}
                <Input variant='danger' label={coreContext.message} elementType='label' />
            </div>
            <div className="card-body">
                <div className="card-title mx-auto"><h3><Badge variant="success">Educational Solutions Images</Badge></h3></div>
                <div className="row">
                    {renderImages()}
                </div>
            </div>
        </div>
    </div>
    )
}

export { EducationSolution }