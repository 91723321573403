/* eslint-disable no-multi-str */
/* eslint-disable jsx-a11y/img-redundant-alt */
import React, { useState, useContext, useEffect } from 'react';
import ImageUploader from './common/UploadImage';
import Axios from 'axios';
import { useHistory } from 'react-router-dom';
import { Editor } from '@tinymce/tinymce-react';
import Input from './common/Input';
import { Badge, Form } from 'react-bootstrap';
import { XSquare } from 'react-bootstrap-icons';
import { CoreContext } from '../context/core-context';
import { AuthContext } from '../context/auth-context';
import { useForm } from "react-hook-form";
import { SinglePageImage } from './SinglePageImage';

const SinglePage = (props) => {

    const coreContext = useContext(CoreContext);
    const authContext = useContext(AuthContext);
    const [selectedImages, setSelectedImages] = useState([]);
    const [uploadButtonDisabled, setUploadButtonDisabled] = useState(true);
    const [title, setTitle] = useState('');
    const [metaKeyWord, setMetaKeyWord] = useState('');
    const [metaDescription, setMetaDescription] = useState('');
    const [message, setMessage] = useState('');
    const [content, setContent] = useState('');
    const [images, setImages] = useState([]);
    const [slug, setSlug] = useState('');
    const [colSize, setColSize] = useState(4);
    const [isGallery, setIsGallery] = useState(false);
    const [showInMenu, setShowInMenu] = useState(false);
    const [colSizeOptions] = useState([{ name: '1', value: '1' }, { name: '2', value: '2' }, { name: '3', value: '3' }, { name: '4', value: '4' }, { name: '5', value: '5' }, { name: '6', value: '6' }, { name: '7', value: '7' }, { name: '8', value: '8' }, { name: '9', value: '9' }, { name: '10', value: '10' }, { name: '11', value: '11' }, { name: '12', value: '12' }]);

    const history = useHistory();


    const fetchClient = () => {
        coreContext.fetchPage(props.location, authContext.itemId);
    }

    const { register, handleSubmit, errors } = useForm({
        mode: 'onSubmit',
        reValidateMode: 'onBlur',
    });

    useEffect(fetchClient, []);

    const populateClient = () => {
        setTitle(coreContext.page.title);
        setMetaKeyWord(coreContext.page.meta_key_word);
        setMetaDescription(coreContext.page.meta_description);
        setMessage(coreContext.page.message);
        setContent(coreContext.page.pageContent);
        setColSize(coreContext.page.row_col);
        setSlug(coreContext.page.slug);
        setImages(coreContext.page.page_images);
        if (coreContext.page.images === 'yes')
            setIsGallery(true);
        else setIsGallery(false);

        if (coreContext.page.show_in_menu === 'yes')
            setShowInMenu(true);
        else setShowInMenu(false);

       // console.log(props.location);
    }

    useEffect(populateClient, [coreContext.page]);


    const renderImages = () => {
        if (images)
            return images.map(image => <SinglePageImage key={image.id} image={image} />)
         //   return images.map(image => <div style={{ position: 'relative' }} key={image.id} className="col-md-6"><img style={{ padding: 5 }} className="img-fluid" src={`http://${image.url}`} alt='gallery image' /> <button onClick={() => deleteImage(image.id)} style={{ position: 'absolute', top: 0, left: 5 }} type="button" className="btn btn-danger btn-sm" title="Delete Image"> <XSquare size="12" /> </button></div>)
    }

    const onImagesUploadHandler = async () => {
        const count = selectedImages.length;
        let counter = 0;

        for await (const image of selectedImages) {
            ++counter;

            const formData = new FormData();
            formData.append('itemId', authContext.itemId);
            formData.append('pageId', coreContext.page.id);
            formData.append('image', image, image.name);
            formData.append('userId', authContext.userId);
            coreContext.uploadPageImage(formData, authContext.itemId, coreContext.page.id);
            if (counter === count) {
                setTimeout(() => {
                    setSelectedImages([]);
                    coreContext.fetchPageImages(authContext.itemId, coreContext.page.id);
                }, 2000);
                setTimeout(() => {
                    history.push('/pages');
                }, 5000);
            };
        }
    }

    const onTitleChangedHandler = (e) => {
        setTitle(e.target.value);
    }
    const onMetaKeywordChangedHandler = (e) => {
        setMetaKeyWord(e.target.value);
    }
    const onMetaDescriptionChangedHandler = (e) => {
        setMetaDescription(e.target.value);
    }

    const onGalleryChangedHandler = (e) => {
        if (isGallery) {
            setIsGallery(false);
            coreContext.updateIsPageGallery(authContext.itemId, coreContext.page.id, 'no');
        }
        else {
            setIsGallery(true);
            coreContext.updateIsPageGallery(authContext.itemId, coreContext.page.id, 'yes');
        }
    }

    const onSIMChangedHandler = (e) => {
        if (showInMenu) {
            setShowInMenu(false);
            coreContext.showInMenu(authContext.itemId, coreContext.page.id, 'no');
        }
        else {
            setShowInMenu(true);
            coreContext.showInMenu(authContext.itemId, coreContext.page.id, 'yes');
        }
    }

    const onMessageChangedHandler = (e) => {
        setMessage(e.target.value);
    }

    const onColSizeChangedHandler = (e) => {
        setColSize(e.target.value);
    }

    const handleEditorChange = (content, editor) => {
        setContent(content);
    }

    const onSlugChangedHandler = (e) => {
        setSlug(e.target.value);
    }

    const onPageContentHandler = (data) => {
        coreContext.updatePageContent(title, message, content, slug, authContext.itemId, coreContext.page.id, colSize, metaKeyWord, metaDescription);

    }

    const onImagesSelected = (pictures) => {

        setSelectedImages(pictures);
        if (pictures.length > 0) setUploadButtonDisabled(false); else setUploadButtonDisabled(true);
    }

    return (<div className='container-fluid'>
        <div className="card">
            <div className="card-title mx-auto"><h3><Badge variant="success">Edit Page</Badge></h3></div>
            <div className="card-body">
                <Form autoComplete='off' onSubmit={handleSubmit(onPageContentHandler)} noValidate>
                    <div className="row"><div className="col-md-3">
                        <Input label='Title' value={title} name='title' minLength={2} maxLength={155} required={false} register={register} errors={errors} elementType='textarea' placeholder='Enter Title' onChange={onTitleChangedHandler} /> </div>
                        <div className="col-md-4">
                            <Input label='Meta Key Word' value={metaKeyWord} name='mkword' minLength={2} maxLength={555} required={false} register={register} errors={errors} elementType='textarea' placeholder='Enter Meta Key Word' onChange={onMetaKeywordChangedHandler} /> </div>
                        <div className="col-md-5">
                            <Input label='Meta Description' value={metaDescription} name='mkdescription' minLength={2} maxLength={555} required={false} register={register} errors={errors} elementType='textarea' placeholder='Enter Meta Description' onChange={onMetaDescriptionChangedHandler} /> </div>
                    </div>
                    <div className="row"><div className="col-md-5">
                        <Input label='Slug' value={slug} name='slug' minLength={2} maxLength={155} required={true} register={register} errors={errors} elementType='text' placeholder='Enter Slug' onChange={onSlugChangedHandler} />
                    </div>
                        <div className="col-md-5"> <Input elementType='checkbox' value="Does this Page has an Image Gallery? " checked={isGallery} onChange={onGalleryChangedHandler} />
                            <br />
                            <Input elementType='checkbox' value="Show in Menu? " checked={showInMenu} onChange={onSIMChangedHandler} />
                        </div>
                        <div className="col-md-2">
                            {isGallery ? <Input label='Gallery Column Size' name='col_size' required={true} register={register} errors={errors} elementType='select' value={colSize} options={colSizeOptions} onChange={onColSizeChangedHandler} /> : null}
                        </div>
                    </div>
                    <Input label='Image Message' value={message} name='message' minLength={2} maxLength={2000} required={false} register={register} errors={errors} elementType='textarea' placeholder='Enter Message' onChange={onMessageChangedHandler} />

                    <Editor
                        apiKey={'5k5x5ez6z06mvgu1fkuizuc0wa07bqni14qml1baljvj2ijp'}
                        value={content}
                        init={{
                            height: 500,
                            menubar: 'insert',
                            file_picker_types: 'file image media',
                            plugins: [
                                'advlist', 'autolink', 'lists', 'link', 'image', 'charmap', 'preview',
                                'anchor', 'searchreplace', 'visualblocks', 'code', 'fullscreen',
                                'insertdatetime', 'media', 'table', 'code', 'help', 'wordcount'
                            ],
                            toolbar:
                                'undo redo | formatselect | bold italic backcolor forecolor  | fontsize fontfamily lineheight  | \
             alignleft aligncenter alignright alignjustify | \
             bullist numlist outdent indent image | removeformat | code  | help'
                        }}
                        onEditorChange={handleEditorChange}
                    />

                    <Input elementType='button' variant='primary' value="Update" /> &nbsp;&nbsp; {coreContext.renderLoader()}
                    <Input variant='danger' label={coreContext.message} elementType='label' />

                </Form>
            </div>
        </div>
        <div className="card">
            <div className="card-title mx-auto"><h3><Badge variant="success">Upload Page Images</Badge></h3></div>
            <div className="card-title mx-auto">Best Image Size 500*333</div>
            <div className="card-body">
                <ImageUploader onImagesSelected={onImagesSelected} maxWidthOrHeight='500' pictures={selectedImages} />
                <Input value='Upload Images' onClick={onImagesUploadHandler} disabled={uploadButtonDisabled} elementType='button' variant='primary' />&nbsp;&nbsp; {coreContext.renderLoader()}
                <Input variant='danger' label={coreContext.message} elementType='label' />
            </div>
            <div className="card-body">
                <div className="card-title mx-auto"><h3><Badge variant="success">Page Images</Badge></h3></div>
                <div className="row">
                    {renderImages()}
                </div>
            </div>
        </div>
    </div>
    )
}

export { SinglePage }