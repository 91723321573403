/* eslint-disable no-multi-str */
/* eslint-disable jsx-a11y/img-redundant-alt */
import React, { useState, useContext, useEffect } from 'react';
import { Editor } from '@tinymce/tinymce-react';
import { useHistory } from 'react-router-dom';
import Input from './common/Input';
import { Badge, Form } from 'react-bootstrap';
import { CoreContext } from '../context/core-context';
import { AuthContext } from '../context/auth-context';
import { useForm } from "react-hook-form";


const AddGeneral = (props) => {

    const coreContext = useContext(CoreContext);
    const authContext = useContext(AuthContext);
    const [title, setTitle] = useState('');
    const [message, setMessage] = useState('');
    const [content, setContent] = useState('Enter Page Content...');
    const [edate, setEdate] = useState('');
    const [etime, setEtime] = useState('');

    const history = useHistory();

    const redirect = () => {
        if (coreContext.isRequestComplete) history.push('/generals');
    }

    useEffect(redirect, [coreContext.isRequestComplete]);


    const { register, handleSubmit, errors } = useForm({
        mode: 'onSubmit',
        reValidateMode: 'onBlur',
    });

    const onTitleChangedHandler = (e) => {
        setTitle(e.target.value);
    }
    const onMessageChangedHandler = (content, editor) => {
        setMessage(content);
    }
    const onEDateChangedHandler = (e) => {
        setEdate(e.target.value);
    }
    const onETimeChangedHandler = (e) => {
        setEtime(e.target.value);
    }

    const handleEditorChange = (content, editor) => {
        setContent(content);
    }

    const onGeneralContentHandler = (data) => {
        coreContext.addGeneralContent(title, message, content, edate, etime, authContext.itemId, authContext.userId);

    }


    return (<div className='container-fluid'>
        <div className="card">
            <div className="card-title mx-auto"><h3><Badge variant="success">Add New General Item</Badge></h3></div>
            <div className="card-body">
                <Form autoComplete='off' onSubmit={handleSubmit(onGeneralContentHandler)} noValidate>
                    <Input label='Title' value={title} name='title' minLength={2} maxLength={155} required={true} register={register} errors={errors} elementType='textarea' placeholder='Enter Title' onChange={onTitleChangedHandler} />
                    <Form.Group>
                        <Editor
                            apiKey={'5k5x5ez6z06mvgu1fkuizuc0wa07bqni14qml1baljvj2ijp'}
                            value={message ? message : "Enter Message"}
                            init={{
                                height: 200,
                                menubar: 'insert',
                                file_picker_types: 'file image media',
                                plugins: [
                                    'advlist', 'autolink', 'lists', 'link', 'image', 'charmap', 'preview',
                                    'anchor', 'searchreplace', 'visualblocks', 'code', 'fullscreen',
                                    'insertdatetime', 'media', 'table', 'code', 'help', 'wordcount'
                                ],
                                toolbar:
                                    'undo redo | formatselect | bold italic backcolor forecolor  | fontsize fontfamily lineheight | \
             alignleft aligncenter alignright alignjustify | \
             bullist numlist outdent indent image | removeformat | code | help'
                            }}
                            onEditorChange={onMessageChangedHandler}
                        />
                    </Form.Group>


                    <div className="row"><div className="col-md-6">
                        <Input label='Date' value={edate} name='edate' minLength={2} maxLength={50} required={true} register={register} errors={errors} elementType='date' placeholder='Enter Event Date' onChange={onEDateChangedHandler} />
                    </div>
                        <div className="col-md-6">
                            <Input label='Inverse Display Order' value={etime} name='etime' minLength={1} maxLength={5} required={true} register={register} errors={errors} elementType='text' placeholder='Enter Inverse Display Order eg. 5' onChange={onETimeChangedHandler} />
                        </div></div>

                    <Form.Group>
                        <Editor
                            apiKey={'5k5x5ez6z06mvgu1fkuizuc0wa07bqni14qml1baljvj2ijp'}
                            value={content}
                            init={{
                                height: 500,
                                menubar: 'insert',
                                file_picker_types: 'file image media',
                                plugins: [
                                    'advlist', 'autolink', 'lists', 'link', 'image', 'charmap', 'preview',
                                    'anchor', 'searchreplace', 'visualblocks', 'code', 'fullscreen',
                                    'insertdatetime', 'media', 'table', 'code', 'help', 'wordcount'
                                ],
                                toolbar:
                                    'undo redo | formatselect | bold italic backcolor forecolor | \
             alignleft aligncenter alignright alignjustify | \
             bullist numlist outdent indent image | removeformat | code | help'
                            }}
                            onEditorChange={handleEditorChange}
                        />
                    </Form.Group>
                    <Input elementType='button' variant='primary' value="Update" /> &nbsp;&nbsp; {coreContext.renderLoader()}
                    <Input variant='danger' label={coreContext.message} elementType='label' />
                </Form>
            </div>
        </div>
    </div>
    )
}

export { AddGeneral }