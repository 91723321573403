import React from 'react';
import ReactDOM from 'react-dom';
import './index.css';
import App from './App';
import * as serviceWorker from './serviceWorker';
import 'bootstrap/dist/css/bootstrap.min.css';
import AuthContextProvider from './context/auth-context';
import CoreContextProvider from './context/core-context';
import axios from 'axios';

//const store = createStore(reducers, applyMiddleware(thunk));
//axios.defaults.baseURL = 'http://localhost:4040'; //local
axios.defaults.baseURL = 'https://node-websites.siddhantait.com'; //server

ReactDOM.render(
  <React.StrictMode>

    <AuthContextProvider>
      <CoreContextProvider>
        <App />
      </CoreContextProvider>
    </AuthContextProvider>

  </React.StrictMode>,
  document.getElementById('root')
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
