import React, { useState, useContext } from "react";
import { Form, Badge } from 'react-bootstrap';
import { useForm } from "react-hook-form";
import Input from './common/Input';
import { AuthContext } from '../context/auth-context';


const Join = (props) => {

    const authContext = useContext(AuthContext);

    const [email, setEmail] = useState('');
    const [name, setName] = useState('');
    const [password, setPassword] = useState('');

    const { register, handleSubmit, errors } = useForm({
        mode: 'onSubmit',
        reValidateMode: 'onBlur',
    });
    // const onSubmit = data => console.log(data);

    const signUpHandler = (data) => {
        //  console.log(name, email, password);
        authContext.join(name, email, password);
        // console.log(data);
    }


    const onEmailChangedHandler = (e) => {
        setEmail(e.target.value);
    }

    const onNameChangedHandler = (e) => {
        setName(e.target.value);
    }

    const onPasswordChangedHandler = (e) => {
        setPassword(e.target.value);
    }


    return (
        <div className='container'>
            <div className="row">
                <div className="col-md-2"></div>
                <div className="col-md-8">
                    <div className="card">
                        <div className="card-title mx-auto"><h3><Badge variant="success">Join Us</Badge></h3></div>
                        <div className="card-body">
                            <Form autoComplete='off' onSubmit={handleSubmit(signUpHandler)} noValidate>
                                <Input label='Name' name='name' minLength={5} maxLength={55} required={true} register={register} errors={errors} elementType='text' placeholder='Enter Name' onChange={onNameChangedHandler} />
                                <Input label='Email' name='email' required={true} register={register} errors={errors} elementType='email' minLength={5} maxLength={55} pattern={/[a-z0-9!#$%&'*+/=?^_`{|}~-]+(?:\.[a-z0-9!#$%&'*+/=?^_`{|}~-]+)*@(?:[a-z0-9](?:[a-z0-9-]*[a-z0-9])?\.)+[a-z0-9](?:[a-z0-9-]*[a-z0-9])?/} placeholder='Enter Email' onChange={onEmailChangedHandler} />
                                <Input label='Password' required={true} register={register} errors={errors} name='password' minLength={5} maxLength={55} elementType='password' placeholder='Enter Password' onChange={onPasswordChangedHandler} />
                                <Input elementType='button' variant='primary' /> &nbsp;&nbsp; {authContext.ren}
                                <Input variant='danger' label={authContext.message} elementType='label' />
                            </Form>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );

}



export { Join };