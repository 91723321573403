/* eslint-disable jsx-a11y/img-redundant-alt */
import React, { useState, useContext, useEffect } from 'react';
import ImageUploader from './common/UploadImage';
import Input from './common/Input';
import { useHistory } from 'react-router-dom';
import { Badge, Form } from 'react-bootstrap';
import { CoreContext } from '../context/core-context';
import { AuthContext } from '../context/auth-context';
import { useForm } from "react-hook-form";


const RegisterNow = () => {

    const coreContext = useContext(CoreContext);
    const authContext = useContext(AuthContext);
    const [selectedImages, setSelectedImages] = useState([]);
    const [uploadButtonDisabled, setUploadButtonDisabled] = useState(true);
    const [title, setTitle] = useState('');
    const [message, setMessage] = useState('');
    const [image, setImage] = useState('');

    const history = useHistory();

    const fetchClient = () => {
        coreContext.fetchSelfRegisterNow(authContext.itemId);
    }

    const { register, handleSubmit, errors } = useForm({
        mode: 'onSubmit',
        reValidateMode: 'onBlur',
    });

    useEffect(fetchClient, []);

    const populateClient = () => {
        setTitle(coreContext.client.register_now_title);
        setMessage(coreContext.client.register_now_message);
        setImage(coreContext.client.register_now_image);
    }

    useEffect(populateClient, [coreContext.client]);

    const renderImages = () => {
        if (image)
            return <div className="col-md-6"><img style={{ padding: 5 }} className="img-fluid" src={`http://${image}`} alt='register now image' /></div>
    }

    const onImagesUploadHandler = async () => {
        const count = selectedImages.length;
        if (count > 1) { alert('Only one image should be uploaded for this section !'); return; }
        let counter = 0;

        for await (const image of selectedImages) {
            ++counter;
            const formData = new FormData();
            formData.append('itemId', coreContext.client.id);
            formData.append('image', image, image.name);
            formData.append('userId', authContext.userId);
            coreContext.uploadRegisterNowImage(formData, coreContext.client.id);

            if (counter === count) {
                setTimeout(() => {
                    setSelectedImages([]);
                    fetchClient();
                }, 2000);

                setTimeout(() => {
                    history.push('/');
                }, 5000);
            };
        }



    }

    const onTitleChangedHandler = (e) => {
        setTitle(e.target.value);
    }
    const onMessageChangedHandler = (e) => {
        setMessage(e.target.value);
    }

    const onRegisterNowSectionHandler = (data) => {
        coreContext.updateRegisterNowSection(title, message, authContext.itemId);

    }

    const onImagesSelected = (pictures) => {
        setSelectedImages(pictures);
        //  console.log(pictures);
        if (pictures.length > 0) setUploadButtonDisabled(false); else setUploadButtonDisabled(true);
    }

    return (<div className='container-fluid'>
        <div className="card">
            <div className="card-title mx-auto"><h3><Badge variant="success">Register Now Section</Badge></h3></div>
            <div className="card-body">
                <Form autoComplete='off' onSubmit={handleSubmit(onRegisterNowSectionHandler)} noValidate>
                    <Input label='Title' value={title} name='title' minLength={2} maxLength={155} required={false} register={register} errors={errors} elementType='textarea' placeholder='Enter Title' onChange={onTitleChangedHandler} />

                    <Input label='Message' value={message} name='message' minLength={2} maxLength={2000} required={false} register={register} errors={errors} elementType='textarea' placeholder='Enter Message' onChange={onMessageChangedHandler} />

                    <Input elementType='button' variant='primary' value="Update" /> &nbsp;&nbsp; {coreContext.renderLoader()}
                    <Input variant='danger' label={coreContext.message} elementType='label' />
                </Form>
            </div>
        </div>
        <div className="card">
            <div className="card-title mx-auto"><h3><Badge variant="success">Upload Register Now Image</Badge></h3></div>
            <div className="card-title mx-auto">Best Image Size 1920*1000</div>
            <div className="card-body">
                <ImageUploader onImagesSelected={onImagesSelected} maxWidthOrHeight='1920' pictures={selectedImages} />
                <Input value='Upload Images' onClick={onImagesUploadHandler} disabled={uploadButtonDisabled} elementType='button' variant='primary' />&nbsp;&nbsp; {coreContext.renderLoader()}
                <Input variant='danger' label={coreContext.message} elementType='label' />
            </div>
            <div className="card-body">
                <div className="card-title mx-auto"><h3><Badge variant="success">Register Now Image</Badge></h3></div>
                <div className="row">
                    {renderImages()}
                </div>
            </div>
        </div>
    </div>
    )
}

export { RegisterNow }